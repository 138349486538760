import { LMFeature } from "@livingmap/core-mapping";
import { Geometry, Feature } from "geojson";
import { GeoJSONFeature } from "mapbox-gl";

import { SearchedFeatureData } from "@redux/services/types";
import { SourceIds } from "@components/Map/plugins/types";

export const createLMFeature = (
  feature: SearchedFeatureData,
  language: string,
) => {
  return new LMFeature({
    type: "Feature",
    geometry: feature.geometry as Geometry,
    id: feature.id,
    properties: {
      ...feature.properties,
      uid: feature.id,
      geom: feature.geometry,
      centroid: feature.geometry,
      is_geojson: true,
      name: feature.properties.name.find((item) => item.lang === language)
        ?.text,
      popup_header: feature.properties.popup_header.find(
        (item) => item.lang === language,
      )?.text,
    },
    layer: {
      id: feature.layer.id,
      type: feature.layer.type as any,
      source: SourceIds.LM_SOURCE_ID,
    },
    state: {},
    source: feature.source,
    sourceLayer: feature.sourceLayer,
    clone: () => {
      return {} as GeoJSONFeature;
    },
    toJSON: () => {
      return {} as Feature;
    },
  } as GeoJSONFeature);
};
